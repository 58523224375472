<template>
        <base-page>
        <titlu-pagina Titlu="Dosare deschise" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
            <el-col :span='4'>
                <el-form-item label='Proiect' >
                     <el-select class='full-width' v-model='Filters.IdProiect' ><el-option label='Toate' value='-1'></el-option>
                        <el-option v-for='item in Info.proiecte' :key="'proiecte' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span='4'>
                <el-form-item label='Numar dosar' >
                    <el-input v-model='Filters.NumarDosar' />
                </el-form-item>
            </el-col>
            <el-col :span='4'>
                <el-form-item label='Data limita preluare' >
                    <el-date-picker v-model='Filters.DataLimitaPreluare' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                </el-form-item>
            </el-col>
            <el-col :span='4'>
                <el-form-item label='Data trimitere la minister' >
                    <el-date-picker v-model='Filters.DataTrimitereLaMinisterPentruAsteptareHG' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                </el-form-item>
            </el-col>
            <el-col :span='4'>
                <el-form-item label='Data publicare' >
                    <el-date-picker v-model='Filters.DataPublicareHGInMonitor' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                </el-form-item>
            </el-col>
            <el-col :span='4'>
                <el-form-item label='Status' >
                     <el-select class='full-width' v-model='Filters.Status' >
                         <el-option label='Toate' value='-1'></el-option>
                         <el-option label='Deschis (In evaluare)' value='deschis'></el-option>
                         <el-option label='In executare' value='in_executare'></el-option>
                         <el-option label='In asteptare inchidere' value='in_asteptare'></el-option>
                         <el-option label='In asteptare HG' value='in_asteptare_hg'></el-option>
                         <el-option label='In asteptare preluare faptica' value='in_asteptare_preluare_faptica'></el-option>
                         <el-option label='Preluat Faptic' value='preluat_faptic'></el-option>
                         <el-option label='Inchis' value='inchis'></el-option>
                     </el-select>
                </el-form-item>
            </el-col>                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='IdProiect' label='Proiect'></el-table-column>
            <el-table-column prop='Descriere' label='Descriere'></el-table-column>
            <el-table-column prop='NumarDosar' label='Numar dosar'></el-table-column>
                        <el-table-column label='Data limita preluare'>
                            <template slot-scope='scope'>
                                {{ scope.row.DataLimitaPreluare | momentformat('dd.MM.YYYY') }}
                            </template>
                        </el-table-column>
                        <el-table-column label='Data trimitere la minister'>
                            <template slot-scope='scope'>
                                {{ scope.row.DataTrimitereLaMinisterPentruAsteptareHG | momentformat('dd.MM.YYYY') }}
                            </template>
                        </el-table-column>
                        <el-table-column label='Data publicare'>
                            <template slot-scope='scope'>
                                {{ scope.row.DataPublicareHGInMonitor | momentformat('dd.MM.YYYY') }}
                            </template>
                        </el-table-column>
                        <el-table-column prop='Status' label='Status'></el-table-column>
                        <el-table-column fixed="right" label="Actiuni" width="200px" >
                            <template slot-scope="scope" >

                                <el-tooltip content="Modificare">
                                    <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                            </el-tooltip>

                            <el-tooltip content="Sterge" >
                                <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                        </el-tooltip>

                        </template> 
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Dosare-deschise-dialog ref='dlg' @save="refresh_info()" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Dosare_deschise_dialog from '@/pages/dosare_deschise/Dosare_deschise_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "dosare_deschise",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Dosare-deschise-dialog': Dosare_deschise_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                proiecte: [],             },
            Filters: {
                IdProiect: '-1' , NumarDosar: '' , DataLimitaPreluare: '' , DataTrimitereLaMinisterPentruAsteptareHG: '' , DataPublicareHGInMonitor: '' , Status: '-1' ,             },
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            var response        = await this.post("dosare_deschise/get_info" );
            this.Info.proiecte = response.proiecte;
if( this.Filters.IdProiect.length > 0 ) this.Filters.IdProiect = this.Info.proiecte[0].Id;
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("dosare_deschise/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('dosare_deschise');
        },
        reset(){
            this.Filters = {
                IdProiect: '-1' , NumarDosar: '' , DataLimitaPreluare: '' , DataTrimitereLaMinisterPentruAsteptareHG: '' , DataPublicareHGInMonitor: '' , Status: '-1' ,             };
            this.refresh_info();
        },


        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("dosare_deschise/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
