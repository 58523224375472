<template>
        <el-dialog title="Dosare deschise" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item label='Proiect' >
                     <el-select v-model='selectedObject.IdProiect'  class='full-width'>        <el-option v-for='item in Info.proiecte' :key="'proiecte' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Descriere' >
                    <el-input  class='full-width' v-model='selectedObject.Descriere' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Numar dosar' >
                    <el-input  class='full-width' v-model='selectedObject.NumarDosar' />
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Dosare_deschise_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    IdProiect: '' , Descriere: '' , NumarDosar: '' ,                 },
                Info:{
                    proiecte: [],                 },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    IdProiect: [ { required: true, message: 'Campul este obligatoriu' } ], 
Descriere: [ { required: false, message: 'Campul este obligatoriu' } ], 
NumarDosar: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("dosare_deschise/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("dosare_deschise/get_info_for_dialog" );
                this.Info.proiecte = response.proiecte;            },
                        save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("dosare_deschise/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>